var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline"}},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addCrime()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Catatan Kriminal")],1),_c('br'),_c('br'),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.crimeData,"fields":_vm.crimeFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewDetail(row.item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_vm._v(" "),(_vm.isValidUser)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeData(row.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center my-3"},[_vm._v(" Tidak ada data yang tersedia saat ini. ")])]},proxy:true}])}),_c('b-modal',{attrs:{"title":"Tambah Catatan Kriminal","hide-footer":"","size":"lg"},model:{value:(_vm.crimeModal),callback:function ($$v) {_vm.crimeModal=$$v},expression:"crimeModal"}},[(_vm.LoadingCrime)?_c('loading'):_vm._e(),_c('validation-observer',{ref:"usercrime",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitCrime($event)}}},[(!_vm.LoadingCrime)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitCrime($event)}}},[_c('b-form-group',{attrs:{"label":"Denda"}},[_c('validation-provider',{attrs:{"name":"pasal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.pasalOption,"multiple":false,"close-on-select":true,"clear-on-select":true,"placeholder":"Pilih Denda Tertinggi","label":"text","track-by":"value"},model:{value:(_vm.selectedPasal),callback:function ($$v) {_vm.selectedPasal=$$v},expression:"selectedPasal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1081723847)})],1),_c('b-form-group',{attrs:{"label":"Kasus"}},[_c('validation-provider',{attrs:{"name":"judul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"locale":"en"},model:{value:(_vm.formCrime.judul),callback:function ($$v) {_vm.$set(_vm.formCrime, "judul", $$v)},expression:"formCrime.judul"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3886635532)})],1),_c('b-form-group',{attrs:{"label":"Keterangan / Pasal dikenakan"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description"},model:{value:(_vm.formCrime.description),callback:function ($$v) {_vm.$set(_vm.formCrime, "description", $$v)},expression:"formCrime.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4227036860)})],1),_c('b-form-group',{attrs:{"label":"Url Image"}},[_c('validation-provider',{attrs:{"name":"link image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"locale":"en"},model:{value:(_vm.formCrime.urlimg),callback:function ($$v) {_vm.$set(_vm.formCrime, "urlimg", $$v)},expression:"formCrime.urlimg"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3492630758)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Simpan")])],1):_vm._e()],1)],1),_c('b-modal',{attrs:{"title":"Crime Detail","hide-footer":"","size":"lg","centered":""},model:{value:(_vm.detailCrimeModal),callback:function ($$v) {_vm.detailCrimeModal=$$v},expression:"detailCrimeModal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Kasus"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.judul)+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Denda"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.pasal_denda)+" ")])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Pasal Type"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.pasal_type)+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan / Pasal Dikenakan"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.description)+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Petugas"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.inserted_by_name)+" ")])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal"}},[_c('div',{staticClass:"input-like-div",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.crimeDetail.formated_inserted_date)+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }