<template>
  <div style="display:inline">
    <b-button @click="addLicense()" size="sm" variant="primary"><font-awesome-icon icon="plus"/> Lisensi</b-button>
    <br/>
    <br/>
    <b-table striped hover :items="licenseData" :fields="licenseFields" show-empty>
      <template v-slot:cell(action)="row">
        <b-button @click="removeData(row.item)" variant="danger" size="sm"><feather-icon icon="TrashIcon" /></b-button>&nbsp;
      </template>
      <template #empty>
        <div class="text-center my-3">
          Tidak ada data yang tersedia saat ini.
        </div>
      </template>
    </b-table>

    <b-modal v-model="showLicenseModal" title="Tambah Lisensi" hide-footer>
      <loading v-if="Loading"></loading>
      <validation-observer v-if="!Loading" ref="license" tag="form" @submit.prevent="submitLisensi">
        <b-form v-if="!Loading" @submit.prevent="submitLisensi">
          <b-form-group label="Tipe Lisensi">
            <validation-provider #default="{ errors }" name="Lisensi" rules="required">
              <multiselect @input="chainLicense()" v-model="selectedLicenseType" :options="licenceTypeOption" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Lisensi" label="text" track-by="value"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="showNomorSim">
            <validation-provider #default="{ errors }" name="addon_sim_type" rules="required">
              <div class="d-flex">
                <b-form-checkbox v-for="option in simOptions" :key="option.value" v-model="selectedSimType" :value="option.value" class="mr-3">
                  {{ option.label }}
                </b-form-checkbox>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Nama Steam" v-if="showNomorSim">
            <validation-provider #default="{ errors }" name="steamname" rules="required">
              <b-form-input v-model="formLisensi.steam_name" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Nomor Sim" v-if="showNomorSim">
            <validation-provider #default="{ errors }" name="nomorsim" rules="required">
              <b-form-input v-model="formLisensi.nomor" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Link Image" v-if="showNomorSim">
            <validation-provider #default="{ errors }" name="imgurl">
              <b-form-input v-model="formLisensi.imgurl" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Start">
            <validation-provider #default="{ errors }" name="start" rules="required">
              <b-form-datepicker v-model="formLisensi.start" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="End">
            <validation-provider #default="{ errors }" name="end" rules="required">
              <b-form-datepicker v-model="formLisensi.end" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" size="sm" variant="primary">Simpan</b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect
  },
  props:{
    user: {
      type: [Array, Object],
      default: () => [],
    },
    licenseData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      userid: null,
      Loading: false,
      selectedSimType: [],
      showLicenseModal: false,
      selectedLicenseType : null,
      formLisensi:_interfaces.lisensi,
      formLisensi: {
        addon_tipe_sim: [] // Ini harus berupa array
      },
      simOptions: [
        { label: 'Sim A', value: 1 },
        { label: 'Sim B', value: 2 },
        { label: 'Sim C', value: 3 }
      ],
      licenceTypeOption: [],
      showNomorSim: false,
      licenseFields: [
        { key: "type_name", label: "Jenis Lisensi" },
        { key: "periode", label: "Periode" },
        { key: "created_by_name", label: "By" },
        { key: "action", label: "Action" },
      ]
    };
  },
  methods: {
    init: function() {
      this.getLicenseType();
    },
    chainLicense(){
      if(this.selectedLicenseType.value < 4){
        this.showNomorSim = true;
      }else{
        this.showNomorSim = false;
      }
    },
    getLicenseType: function(){
      axios.get("idp_dropdown/list").then((response) => {
        this.licenceTypeOption = response.data.license_type
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.showLicenseModal = false;
      });
    },
    addLicense: function(){
      this.resetForm();
      this.showNomorSim = false
      this.showLicenseModal = true;
    },
    submitLisensi: function(){
      this.$refs.license.validate().then((success) => {
        if(success){ 
          this.Loading = true;
          setTimeout(() => {
            this.formLisensi.id = this.user.id;
            this.formLisensi.type = this.selectedLicenseType.value;
            this.formLisensi.addon_tipe_sim = this.selectedSimType;
            axios.post("idp/lisensi/add",this.formLisensi).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              }
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.Loading = false;
              this.showLicenseModal = false;
              this.resetForm();
              this.$emit('syncLicense')
            });
          }, 1000);

        }
      });
    },
    removeData: function(row){
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Yes, delete it!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          axios.post("idp/lisensi/delete",{id:row.id}).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success",
                icon: "success",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          }).finally(() => {
            this.$emit('syncLicense')
          });
        }
      })
    },
    resetForm() {
      for (const i in _interfaces.lisensi) {
        _interfaces.lisensi[i] = null
      }
      this.formLisensi = _interfaces.lisensi
      this.selectedLicenseType = null
      this.selectedSimType = []
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>