var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline"}},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addLicense()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Lisensi")],1),_c('br'),_c('br'),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.licenseData,"fields":_vm.licenseFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeData(row.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center my-3"},[_vm._v(" Tidak ada data yang tersedia saat ini. ")])]},proxy:true}])}),_c('b-modal',{attrs:{"title":"Tambah Lisensi","hide-footer":""},model:{value:(_vm.showLicenseModal),callback:function ($$v) {_vm.showLicenseModal=$$v},expression:"showLicenseModal"}},[(_vm.Loading)?_c('loading'):_vm._e(),(!_vm.Loading)?_c('validation-observer',{ref:"license",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitLisensi($event)}}},[(!_vm.Loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitLisensi($event)}}},[_c('b-form-group',{attrs:{"label":"Tipe Lisensi"}},[_c('validation-provider',{attrs:{"name":"Lisensi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.licenceTypeOption,"multiple":false,"close-on-select":true,"clear-on-select":true,"placeholder":"Pilih Lisensi","label":"text","track-by":"value"},on:{"input":function($event){return _vm.chainLicense()}},model:{value:(_vm.selectedLicenseType),callback:function ($$v) {_vm.selectedLicenseType=$$v},expression:"selectedLicenseType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,492281646)})],1),(_vm.showNomorSim)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"addon_sim_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.simOptions),function(option){return _c('b-form-checkbox',{key:option.value,staticClass:"mr-3",attrs:{"value":option.value},model:{value:(_vm.selectedSimType),callback:function ($$v) {_vm.selectedSimType=$$v},expression:"selectedSimType"}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4191505446)})],1):_vm._e(),(_vm.showNomorSim)?_c('b-form-group',{attrs:{"label":"Nama Steam"}},[_c('validation-provider',{attrs:{"name":"steamname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"locale":"en"},model:{value:(_vm.formLisensi.steam_name),callback:function ($$v) {_vm.$set(_vm.formLisensi, "steam_name", $$v)},expression:"formLisensi.steam_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,726259567)})],1):_vm._e(),(_vm.showNomorSim)?_c('b-form-group',{attrs:{"label":"Nomor Sim"}},[_c('validation-provider',{attrs:{"name":"nomorsim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"locale":"en"},model:{value:(_vm.formLisensi.nomor),callback:function ($$v) {_vm.$set(_vm.formLisensi, "nomor", $$v)},expression:"formLisensi.nomor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2132311880)})],1):_vm._e(),(_vm.showNomorSim)?_c('b-form-group',{attrs:{"label":"Link Image"}},[_c('validation-provider',{attrs:{"name":"imgurl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"locale":"en"},model:{value:(_vm.formLisensi.imgurl),callback:function ($$v) {_vm.$set(_vm.formLisensi, "imgurl", $$v)},expression:"formLisensi.imgurl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1271091217)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Start"}},[_c('validation-provider',{attrs:{"name":"start","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"locale":"en"},model:{value:(_vm.formLisensi.start),callback:function ($$v) {_vm.$set(_vm.formLisensi, "start", $$v)},expression:"formLisensi.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1206265213)})],1),_c('b-form-group',{attrs:{"label":"End"}},[_c('validation-provider',{attrs:{"name":"end","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"locale":"en"},model:{value:(_vm.formLisensi.end),callback:function ($$v) {_vm.$set(_vm.formLisensi, "end", $$v)},expression:"formLisensi.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2766133266)})],1),_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"primary"}},[_vm._v("Simpan")])],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }