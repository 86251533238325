<template>
  <div style="display:inline">
    <b-button @click="addLicense()" size="sm" variant="primary"><font-awesome-icon icon="plus"/> STNK</b-button>
    <br/>
    <br/>
    <b-table striped hover :items="stnkData" :fields="licenseFields" show-empty>
      <template v-slot:cell(action)="row">
        <b-button @click="removeData(row.item)" variant="danger" size="sm"><feather-icon icon="TrashIcon" /></b-button>&nbsp;
      </template>
      <template #empty>
        <div class="text-center my-3">
          Tidak ada data yang tersedia saat ini.
        </div>
      </template>
    </b-table>

    <b-modal v-model="showLicenseModal" title="Buat STNK" hide-footer>
      <loading v-if="Loading"></loading>
      <validation-observer v-if="!Loading" ref="license" tag="form" @submit.prevent="submitLisensi">
        <b-form v-if="!Loading" @submit.prevent="submitLisensi">
          <b-form-group label="Kendaraan">
            <validation-provider #default="{ errors }" name="Lisensi" rules="required">
              <multiselect v-model="selectedKendaraan" :options="listKendaraan" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Kendaraan" label="text" track-by="value"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Warna">
            <validation-provider #default="{ errors }" name="Warna" rules="required">
              <b-form-input v-model="formStnk.warna"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Nama Steam">
            <validation-provider #default="{ errors }" name="Nama Steam" rules="required">
              <b-form-input v-model="formStnk.steam_name"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Start">
            <validation-provider #default="{ errors }" name="start" rules="required">
              <b-form-datepicker v-model="formStnk.start" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="End">
            <validation-provider #default="{ errors }" name="end" rules="required">
              <b-form-datepicker v-model="formStnk.end" locale="en"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" size="sm" variant="primary">Simpan</b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect
  },
  props:{
    user: {
      type: [Array, Object],
      default: () => [],
    },
    stnkData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      userid: null,
      Loading: false,
      showLicenseModal: false,
      selectedKendaraan : null,
      formStnk:_interfaces.stnk,
      listKendaraan: [],
      licenceTypeOption: [],
      licenseFields: [
        { key: "plate", label: "Plat" },
        { key: "type_name", label: "Model Name" },
        { key: "periode", label: "Periode" },
        { key: "created_by_name", label: "By" },
        { key: "action", label: "Action" },
      ]
    };
  },
  methods: {
    init: function() {
      this.getListKendaraan();
    },
    getListKendaraan: function(){
      axios.get("idp_dropdown/list-kendaraan-user/"+this.$route.params.id).then((response) => {
        this.listKendaraan = response.data.kendaraan
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.showLicenseModal = false;
      });
    },
    addLicense: function(){
      this.resetForm();
      this.showLicenseModal = true;
    },
    submitLisensi: function(){
      this.$refs.license.validate().then((success) => {
        if(success){ 
          this.Loading = true;
          setTimeout(() => {
            this.formStnk.identifier = this.user.identifier;
            this.formStnk.plate = this.selectedKendaraan.value;
            axios.post("idp/stnk/add",this.formStnk).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              }
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.Loading = false;
              this.showLicenseModal = false;
              this.resetForm();
              this.$emit('syncStnk')
            });
          }, 1000);

        }
      });
    },
    removeData: function(row){
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Yes, delete it!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          axios.post("idp/stnk/delete",{id:row.id}).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success",
                icon: "success",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          }).finally(() => {
            this.$emit('syncStnk')
          });
        }
      })
    },
    resetForm() {
      for (const i in _interfaces.stnk) {
        _interfaces.stnk[i] = null
      }
      this.formStnk = _interfaces.stnk
      this.selectedKendaraan = null
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>