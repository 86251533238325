var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline"}},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addLicense()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" STNK")],1),_c('br'),_c('br'),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.stnkData,"fields":_vm.licenseFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeData(row.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center my-3"},[_vm._v(" Tidak ada data yang tersedia saat ini. ")])]},proxy:true}])}),_c('b-modal',{attrs:{"title":"Buat STNK","hide-footer":""},model:{value:(_vm.showLicenseModal),callback:function ($$v) {_vm.showLicenseModal=$$v},expression:"showLicenseModal"}},[(_vm.Loading)?_c('loading'):_vm._e(),(!_vm.Loading)?_c('validation-observer',{ref:"license",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitLisensi($event)}}},[(!_vm.Loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitLisensi($event)}}},[_c('b-form-group',{attrs:{"label":"Kendaraan"}},[_c('validation-provider',{attrs:{"name":"Lisensi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.listKendaraan,"multiple":false,"close-on-select":true,"clear-on-select":true,"placeholder":"Pilih Kendaraan","label":"text","track-by":"value"},model:{value:(_vm.selectedKendaraan),callback:function ($$v) {_vm.selectedKendaraan=$$v},expression:"selectedKendaraan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3350980460)})],1),_c('b-form-group',{attrs:{"label":"Warna"}},[_c('validation-provider',{attrs:{"name":"Warna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formStnk.warna),callback:function ($$v) {_vm.$set(_vm.formStnk, "warna", $$v)},expression:"formStnk.warna"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3760304094)})],1),_c('b-form-group',{attrs:{"label":"Nama Steam"}},[_c('validation-provider',{attrs:{"name":"Nama Steam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formStnk.steam_name),callback:function ($$v) {_vm.$set(_vm.formStnk, "steam_name", $$v)},expression:"formStnk.steam_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1099042595)})],1),_c('b-form-group',{attrs:{"label":"Start"}},[_c('validation-provider',{attrs:{"name":"start","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"locale":"en"},model:{value:(_vm.formStnk.start),callback:function ($$v) {_vm.$set(_vm.formStnk, "start", $$v)},expression:"formStnk.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,858894200)})],1),_c('b-form-group',{attrs:{"label":"End"}},[_c('validation-provider',{attrs:{"name":"end","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"locale":"en"},model:{value:(_vm.formStnk.end),callback:function ($$v) {_vm.$set(_vm.formStnk, "end", $$v)},expression:"formStnk.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1760558807)})],1),_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"primary"}},[_vm._v("Simpan")])],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }